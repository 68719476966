@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Fira Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  font-family: "Fira Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Fira Sans";
  src: url("../src/assets/fonts/Fira_Sans_woff/FiraSans-Regular.woff2")
      format("woff2"),
    url("../src/assets/fonts/Fira_Sans_woff/FiraSans-Bold.woff2")
      format("woff2"),
    url("../src/assets/fonts/Fira_Sans_woff/FiraSans-Light.woff2")
      format("woff2"),
    url("../src/assets/fonts/Fira_Sans_woff/FiraSans-Italic.woff2")
      format("woff2");
  font-weight: bold, normal, light;
  font-style: normal;
}
